<script lang="ts" setup>
import { useData } from 'vitepress'
import NavMenu from './NavMenu.vue';

const { site } = useData()

const navLinks = [
  { title: 'Was wir tun', id: '/#was-wir-tun' },
  { title: 'Wie wir arbeiten', id: '/#wie-wir-arbeiten' },
  { title: 'Jobs', id: '/jobs.html' },
  { title: 'Referenzen', id: '/referenzen.html' },
  { title: 'Kontakt', id: '/kontakt.html' },
];

const scrollToTop = () => window.scrollTo({ top: 0 })
</script>

<template>
  <nav class="navbar navbar-light">
    <div class="container">
      <a
        :href="site.base"
        aria-label="Zurück auf die Startseite"
        class="navbar-brand"
        @click="scrollToTop"
      >
        <img
          class="logo"
          src="../assets/images/as-logo.min.svg?url"
          alt="about:source Logo"
        >
      </a>
      <NavMenu :nav-links="navLinks" />
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  padding: 0;
  background-color: white;
  height: $nav-bar-height;
  box-shadow: 0 0.1rem 0.5rem #999;
}

.container,
.navbar-brand,
.logo {
  height: 100%;
}

.logo {
  padding: 1rem 0;
}
</style>
