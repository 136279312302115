<script lang="ts" setup>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import './utils/print-banner'
import useActiveNavLinks from './utils/activeNavLink'

useActiveNavLinks()
</script>

<template>
  <SiteHeader />
  <main>
    <Content />
  </main>
  <SiteFooter />
</template>

<style scoped>
main {
  min-height: 100%;
}
</style>
