<script lang="ts" setup>
import { useRoute, withBase } from 'vitepress';
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import HamburgerButton from './HamburgerButton.vue'

type NavLink = { title: string, id: string }

const props = defineProps<{
  navLinks: NavLink[];
}>();

const menuOpen = ref(false);
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
}

const route = useRoute()
watch(route, () => { menuOpen.value = false })

const links = computed(() => props.navLinks.map(
  (link) => ({ ...link, url: withBase(link.id) }),
))

const resizeListener = () => { menuOpen.value = false }
onMounted(() => {
  window.addEventListener('resize', resizeListener);
})
onUnmounted(() => {
  window.removeEventListener('resize', resizeListener);
})
</script>

<template>
  <div id="menu" :class="{ open: menuOpen }">
    <a
      v-for="link in links"
      :key="link.id"
      :href="link.url"
      class="nav-link"
      :aria-current="link.url === route.path ? 'page' : false"
      @click="menuOpen = false"
    >
      {{ link.title }}
    </a>
  </div>
  <HamburgerButton :is-open="menuOpen" @click="toggleMenu" />
</template>

<style lang="scss" scoped>
#menu {
  position: absolute;
  top: $nav-bar-height;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 0 2rem;
  background: linear-gradient(45deg, var(--bs-primary), var(--bs-primary-light));
  height: 0;
  transition:
    padding 0.2s ease-out,
    height 0.2s ease-out;

  a {
    text-align: right;

    @include font-size(3rem);

    color: white;
    display: block;
    text-decoration: none;
    font-family: var(--bs-font-headings);

    &:hover, &:focus {
      text-decoration: underline;
      opacity: 0.9;
    }
  }

  &.open {
    height: calc(75vh - $nav-bar-height);
    padding: 2rem;
  }

  @include media-breakpoint-up(xl) {
    height: $nav-bar-height;
    position: static;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background: white;

    a {
      padding: 5px 1rem 0;
      color: black;
      display: flex;
      align-items: center;
      border-bottom: 5px solid transparent;

      @include font-size(1rem);

      &:hover, &:focus {
        text-decoration: none;
        border-bottom: 5px solid #555;
      }

      &[aria-current="page"] {
        border-bottom: 5px solid red;
      }
    }
  }
}
</style>
