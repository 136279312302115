import './styles/index.scss'

import { Theme } from 'vitepress'
import PageLayout from './PageLayout.vue'
import NotFound from './NotFound.vue'

const theme: Theme = {
  Layout: PageLayout,
  NotFound,
}

export default theme
