<script lang="ts" setup>
import {
  ref, onMounted, onUnmounted, watch,
} from 'vue';
import image from './GuteBesserung.png';

const active = ref(false);
let direction = 0;
const position = { x: 0, y: 0 };
const inputChars: string[] = [];
const htmlElement = document.querySelector('html');
const guteBesserung = ref<HTMLDivElement | null>(null);

const activateGuteBesserung = (event: KeyboardEvent) => {
  inputChars.push(event.key);

  if (inputChars.slice(-4).join('') === 'robs') {
    active.value = true;
  }
}

onMounted(() => {
  htmlElement?.addEventListener('keydown', activateGuteBesserung);
});

onUnmounted(() => {
  htmlElement?.removeEventListener('keydown', activateGuteBesserung);
});

const normalizeDirection = (dir: number): number => {
  const normalDir = dir % (2 * Math.PI);
  return (normalDir < 0) ? (2 * Math.PI) - normalDir : normalDir;
}

const tick = () => {
  if (guteBesserung.value === null) return;

  const maxX = htmlElement?.clientWidth || 0;
  const maxY = htmlElement?.clientHeight || 0;

  direction += (Math.random() * 0.1) - 0.05;
  position.x += Math.cos(direction);
  position.y += Math.sin(direction);

  if (position.x < 0 || position.x > maxX) {
    if (Math.floor(direction / Math.PI) % 2 === 0) {
      direction = normalizeDirection(Math.PI - direction);
    } else {
      direction = normalizeDirection((3 * Math.PI) - direction);
    }
    position.x = (position.x < 0) ? 0 : maxX;
  }
  if (position.y < 0 || position.y > maxY) {
    direction = normalizeDirection((2 * Math.PI) - direction);
    position.y = (position.y < 0) ? 0 : maxY;
  }

  guteBesserung.value.style.left = `${position.x}px`;
  guteBesserung.value.style.top = `${position.y}px`;
  guteBesserung.value.style.transform = `rotate(${(direction * 180) / Math.PI}deg)`;
};

watch(active, () => {
  if (!active.value) return;

  direction = Math.random() * 2 * Math.PI;
  position.x = Math.random() * (htmlElement?.clientWidth || 0);
  position.y = Math.random() * (htmlElement?.clientHeight || 0);
  window.setInterval(tick, 12);
});
</script>

<template>
  <div v-if="active">
    <img
      ref="guteBesserung"
      class="gute-besserung"
      :src="image"
    >
  </div>
</template>

<style lang="scss" scoped>
.gute-besserung {
  position: fixed;
  margin-left: -244px;
  margin-top: -128px;
  transform-origin: bottom right;
  transition: transform 0.2s;
  z-index: 999;
}
</style>
