const barWidth = 10;
const barGap = 10;
const barIndent = 31;

const css = {
  gradient: `
    display: block;
    background-image: linear-gradient(-90deg, red, orange, yellow, green, blue, indigo, violet);
  `,
  logo: `
    display: block;
    color: white;
    font-weight: bold;
    background-image:
      linear-gradient(
        90deg, transparent, transparent ${barIndent}px, white ${barIndent}px, white ${barIndent + barWidth}px, transparent ${barIndent + barWidth}px, transparent ${barIndent + barWidth + barGap}px, white ${barIndent + barWidth + barGap}px, white ${barIndent + 2 * barWidth + barGap}px, transparent ${barIndent + 2 * barWidth + barGap}px),
      linear-gradient(
        -90deg, red, orange, yellow, green, blue, indigo, violet
      );
    cursor: pointer;
    line-height: 1.3;
  `,
  textBold: 'text-shadow: -1px 0 0, 1px 0 0, 0 -1px 0, 0 1px 0;',
}

// eslint-disable-next-line no-console
console.log(
  `%c                                                                                                        %c
                             _                                            _                             
                            (_)                                          (_)                            
               _  _  _      (_) _  _  _       _  _  _     _         _  _ (_) _  _     _  _              
              (_)(_)(_) _   (_)(_)(_)(_)_  _ (_)(_)(_) _ (_)       (_)(_)(_)(_)(_)   (_)(_)             
               _  _  _ (_)  (_)        (_)(_)         (_)(_)       (_)   (_)         (_)(_)             
             _(_)(_)(_)(_)  (_)        (_)(_)    🦄   (_)(_)       (_)   (_)     _    _  _              
            (_)_  _  _ (_)_ (_) _  _  _(_)(_) _  _  _ (_)(_)_  _  _(_)_  (_)_  _(_)  (_)(_)             
              (_)(_)(_)  (_)(_)(_)(_)(_)     (_)(_)(_)     (_)(_)(_) (_)   (_)(_)    (_)(_)             %c
               _  _  _  _       _  _  _     _         _  _       _  _    _  _  _   _  _  _  _           
             _(_)(_)(_)(_)   _ (_)(_)(_) _ (_)       (_)(_)_  _ (_)(_) _(_)(_)(_) (_)(_)(_)(_)_         
            (_)_  _  _  _   (_)         (_)(_)       (_)  (_)(_)      (_)        (_) _  _  _ (_)        
              (_)(_)(_)(_)_ (_)   🦄    (_)(_)       (_)  (_)         (_)        (_)(_)(_)(_)(_)        
               _  _  _  _(_)(_) _  _  _ (_)(_)_  _  _(_)  (_)         (_)_  _  _ (_)_  _  _  _          
              (_)(_)(_)(_)     (_)(_)(_)     (_)(_)(_)    (_)           (_)(_)(_)  (_)(_)(_)(_)         
%c                                                                                                        `,
  css.gradient,
  css.logo + css.textBold,
  css.logo,
  css.gradient,
)
// eslint-disable-next-line no-console
console.log(`
Hey 🖖 🖖🏽 🖖🏿! Schön dich hier zu treffen. Willst du wissen wie diese Webseite gebaut ist?
Wir liefern alle source maps mit, du kannst dich also in den DevTools austoben. Als Framework nutzen wir VitePress & Bootstrap.

Vielleicht bist du ja auch auf der Suche nach einem Job? Wir können talentierte Entwickler:innen immer gebrauchen! 🚀
https://www.aboutsource.net/jobs.html

`)

export {}
