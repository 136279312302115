<script lang="ts" setup>
import { useData } from 'vitepress'

const { site } = useData()
</script>

<template>
  <div class="text-center">
    <h1 class="glitch" data-text="404">
      404
    </h1>
    <a :href="site.base" aria-label="go to home">Zurück nach Hause</a>
  </div>
</template>

<style lang="scss" scoped>
#app {
  background-color: var(--bs-gray-900);
}

.glitch {
  color: white;
  position: relative;
  display: block;
  padding: 2rem 0;
  font-size: 12rem;
  margin-bottom: 2rem;

  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    background-color: var(--bs-gray-dark);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &::before {
    left: 1px;
    text-shadow: -1px 0 var(--bs-primary);
    animation: noise1 1s linear infinite alternate-reverse;
  }

  &::after {
    left: -1px;
    text-shadow: -1px 0 var(--bs-primary-light);
    animation: noise2 12s linear infinite alternate-reverse;
  }
}

@keyframes noise1 {
  0% { clip-path: inset(45% 0 31% 0); }
  20% { clip-path: inset(92% 0 2% 0); }
  40% { clip-path: inset(33% 0 51% 0); }
  60% { clip-path: inset(65% 0 18% 0); }
  80% { clip-path: inset(24% 0 52% 0); }
  100% { clip-path: inset(38% 0 27% 0); }
}

@keyframes noise2 {
  0% { clip-path: inset(25% 0 41% 0); }
  20% { clip-path: inset(72% 0 12% 0); }
  40% { clip-path: inset(33% 0 51% 0); }
  60% { clip-path: inset(65% 0 8% 0); }
  80% { clip-path: inset(24% 0 62% 0); }
  100% { clip-path: inset(38% 0 37% 0); }
}
</style>
